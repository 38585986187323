<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style=" font-size: 50px; text-align: center;">Pharmaceutical</h1>
                    <!-- <p>A job in the pharmaceutical sector needs a lot of dedication and patience along with the 
                        necessary qualifications and skills. As the number of hospitals, drug stores, chemist shops, 
                        clinics, and healthcare centers has increased, the manpower requirement in this 
                        sector has also gone up.</p>
                    <p>Our team of skilled professionals understands the job requirements of the clients and 
                        serves them accordingly. In order to offer the companies with a cream lot, we strictly 
                        screen the candidates on various aspects before forwarding their resume to the recruiters. 
                        We are equipped with database having rich information about the candidates who are 
                        well-qualified & experienced and are suitable for handling different job roles in the industry.</p>

                    <h1 style="color:green; font-size: 50px; text-align: left;">Healthcare</h1>
                    <p>The Indian healthcare sector, which is expected to reach US$ 200 billion by 2020, is composed of 
                        segments like hospitals, healthcare infrastructure, medical devices, clinical trials, outsourcing, 
                        telemedicine, health insurance and medical equipments. This positive growth sign can be attributed 
                        to rising healthcare expenditure and confidence expressed by the world in Indian healthcare 
                        facilities. Not only the industry is meeting international standards, but is also providing 
                        lucrative salary packages to the healthcare professionals. All this has opened the
                        gateway for a large number of job opportunities. The industry is highly revered and demands 
                        qualified and experienced professionals. We have profiles of several interested professionals, 
                        who possess relevant experience and qualifications for Jobs such as Doctors, Nurses, 
                        Lab Technicians, etc.</p> -->
                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>