<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Our Vision</h1>
                    <p>Our vision is to become global leaders in transforming organizations by enhancing their HR processes and practices. 
                        We strive to motivate individuals and organizations to work more effectively and efficiently, enabling better 
                        decision-making in the workplace for the benefit of all stakeholders.</p>
                    <p></p>
                    <h1 style="color:black; font-size: 50px; text-align: center;">Our Mission</h1>
                    <p><strong>JJR Consultancy</strong> aims to improve organizational effectiveness through strategic human resource management practices. 
                        By leveraging expertise in various aspects of HR, we strive to assist organizations in achieving their 
                        business goals, while ensuring that employees thrive at their workplace. </p>
                    <p>Our objective is to cultivate a culture in organisations, where individuals feel valued and engaged, enabling them 
                        to contribute effectively towards the success of the organization. </p>
                    <p>We are focused on building lasting relationships with our stakeholders including employees, clients, partners, 
                        vendors and communities, fostering mutual trust, respect and collaboration. </p>
                    <p></p>
                    <h1 style="color:black; font-size: 50px; text-align: center;">Company Profile</h1>
                    <p><strong>JJR Consultancy Private Limited, incorporated in 2015,</strong>  provides a comprehensive range of HR related solutions tailored towards businesses across 
                        various industries, which include: </p>
                    <ul>
                       <li>
                            <p><strong>Human Capital Advisory</strong> focuses on assisting companies in developing strategies for optimizing their human resources and providing 
                            guidance on workforce planning, compensation strategies, talent acquisition & retention programs. </p>
                       </li> 
                        <li>
                            <p><strong>Recruitment Services</strong> involve sourcing qualified candidates for positions with client organizations through job 
                                postings & referrals and recruiting top talents after thorough screening processes. </p>
                        </li>
                        <li>
                            <p><strong>Staffing Solutions</strong> entail temporary or permanent placement of personnel and <strong>Payroll Management</strong> 
                                involves processing employees' salaries and handling related statutory compliances. </p>
                        </li>
                        <li>
                            <p><strong>Employee Background Screening </strong> ensures that thorough background checks are conducted to to help 
                                organizations make informed hiring decision and maintain adherence to legal obligations related to employee hiring. </p>
                        </li>
                        <li>
                            <p><strong>Security Services</strong> offer protection measures by supplying trained security personnel for guarding
                                premises and monitoring surveillance systems.  </p>
                        </li>
                    </ul>
                    <p>With years of experience and expertise in HR management, recruitment, staffing and other HR related services, we have built a 
                        strong reputation for delivering excellent results to clients. Our commitment to excellence, innovation, and customer 
                        satisfaction sets us apart from competitors in this space.</p>
                    <p>We believe that investing in employees leads to business success, and we aim to partner with companies looking to
                         achieve long-term growth through strategic talent acquisition, retention, and development initiatives.</p>
                    <p></p>


                    <h1 style="color:black; font-size: 50px; text-align: center;">Management Profile</h1>
                    <!-- Start Team Area -->
                    <div class="team-area ptb-70">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-team-member">
                                        <div class="member-image" >
                                            <img style="width: 184.66px; height: 189.64px;" src="assets/img/management_photos/Manamohan Rout.jpg" alt="image">
                                            <!-- <ul class="social">
                                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            </ul> -->
                                        </div>
                                        <div class="member-content">
                                            <h3>Manamohan Rout </h3>
                                            <span>Chairman & Legal Advisor</span>
                                            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-team-member">
                                        <div class="member-image">
                                            <img style="width: 184.66px; height: 189.64px;" src="assets/img/management_photos/Jagjeeban Rout.jpg" alt="image">
                                            <ul class="social">
                                                <!-- <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li> -->
                                                <li><a href="https://www.linkedin.com/in/jagjeeban/"><i class="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                        <div class="member-content">
                                            <h3>Jagjeeban Rout</h3>
                                            <span>Founder</span>
                                            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-team-member">
                                        <div class="member-image">
                                            <img style="width: 184.66px; height: 189.64px;" src="assets/img/management_photos/Sameer_Kumar_Patel.png" alt="image">
                                            <!-- <ul class="social">
                                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            </ul> -->
                                        </div>
                                        <div class="member-content">
                                            <h3>Sameer Kumar Patel </h3>
                                            <span>Chief Business Officer </span>
                                            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="single-team-member">
                                        <div class="member-image">
                                            <img style="width: 184.66px; height: 189.64px;" src="assets/img/management_photos/Prachi Parichita.jpg" alt="image">
                                            <!-- <ul class="social">
                                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            </ul> -->
                                        </div>
                                        <div class="member-content">
                                            <h3>Prachi Parichita</h3>
                                            <span>Chief Operating Officer</span>
                                            <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley.</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Team Area -->

                <p></p>
                <p></p>
                <p></p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Banner Area -->
<!-- <div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1>Fast and easy business banking</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/" class="btn btn-primary">Create your account</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="fas fa-play"></i> Play Video</a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Banner Area -->

<!-- Start Information Area -->
<!-- <div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3>
                    <p>Free account available</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-user"></i>
                    </div>
                    <h3>10 minutes</h3>
                    <p>Apply for account</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>$100,000</h3>
                    <p>Guarantee on deposits</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                    <div class="btn-box">
                        <a href="#" class="app-store-btn" target="_blank">
                            <i class="flaticon-apple"></i>
                            Download on
                            <span>App Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Information Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Freelancers, entrepreneurs, and sole traders</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Small- to medium-sized businesses</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Large or enterprise level businesses</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- <app-funfact></app-funfact> -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-talk-button></app-talk-button> -->

<!-- <app-partner></app-partner> -->

<!-- <app-download></app-download> -->

<!-- <app-account-button></app-account-button> -->