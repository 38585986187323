import { Component } from '@angular/core';

@Component({
  selector: 'app-retail-hospitality',
  templateUrl: './retail-hospitality.component.html',
  styleUrls: ['./retail-hospitality.component.scss']
})
export class RetailHospitalityComponent {

}
