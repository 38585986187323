<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<p id="pageheader"></p>
<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Staffing Solutions & Payroll Management</h1>
                    <p>We offer comprehensive workforce solutions designed primarily to streamline business operations through temporary staffing, 
                        outsourcing, consulting, payrolling, and other services tailored specifically to businesses' needs. </p>
                    <p>In terms of staffing solutions, we help businesses quickly scale up or down by supplying talented and skilled workforce, 
                        when needed without having to invest in long-term hiring procedures or employee benefits packages. This enables businesses to 
                        focus more efficiently on projects requiring specialized skill sets that may only be available temporarily.</p>
                    <p>In payroll management, we manage employees' salaries, wages, taxes, insurance premiums, retirement contributions, and 
                        other financial aspects related to compensation and benefits. Payroll management ensures accurate calculations, timely 
                        payments, compliance with labour laws, and reduced administrative burdens for businesses.</p>
                    <p>We also provide additional HR consulting services such as employee engagement programs, wellness initiatives, 
                        learning and development programs, training workshops, and performance evaluations to foster optimal productivity 
                        and growth within organizations. </p>
                    <p>By partnering with us, businesses can gain access to pools of vetted and skilled candidates ready for immediate deployment 
                        and benefit from efficient financial and HR management systems saving time, money, and resources overall.</p>
                    <!-- <p><strong>We provide following Office / Facility Management Staff across various industries:</strong></p>
                    <ul>
                        <li>Front Office Executive / Receptionist</li>
                        <li>Back Office Executive</li>
                        <li>Data Entry Operator</li>
                        <li>Typist / Stenographer</li>
                        <li>Tele Operator</li>
                        <li>Office Assistant</li>
                        <li>Time Office Staff</li>
                        <li>Chauffeur</li>
                        <li>Office Boy</li>
                        <li>Pantry Staff</li>
                        <li>Waiter</li>
                        <li>Caretaker</li>
                        <li>Cook & Mess Helper</li>
                    </ul> -->
                    <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>