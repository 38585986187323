import { Component } from '@angular/core';

@Component({
  selector: 'app-information-technology',
  templateUrl: './information-technology.component.html',
  styleUrls: ['./information-technology.component.scss']
})
export class InformationTechnologyComponent {

}
