<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Infrastructure</h1>
                    <!-- <p>The USD 5 Billon Infrastructure industry in India is expected to reach USD 22.5billion by 2020. 
                        Contributing to 11% of the total GDP, the Indian Infrastructure industry is emerging as a 
                        key player in the Indian economy. There has been significant allocation of funds to the 
                        Infrastructure sector in the 12th Five Year Plan, which will create huge demand for construction 
                        engineers and workers.</p>
                    <p><strong>JJR Consultancy</strong> has a huge database of candidate profiles with relevant skills, qualifications 
                        and experience for different jobs in the infrastructure industry along with that of skilled, 
                        semiskilled and unskilled workers.</p> -->
                        <p></p>
                        <p></p>
                        <p></p>
                </div>
             
            </div>
        </div>
    </div>
</div>