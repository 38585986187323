<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<p id="pageheader"></p>
<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Recruitment Services</h1>
                    <p>JJR Consultancy expertise in sourcing qualified candidates for job positions vacancies for its clients. Our main 
                        responsibilities revolve around identifying suitable applicants through various channels like JJR candidate database, 
                        job portals, social media platforms, professional networks, targeted advertising campaigns, referrals from current employees, 
                        and other methods based on industry norms.</p>
                    <p>After identification of potential candidates, JJR consultants screen resumes/applications, conduct interviews, verify 
                        qualifications, assess skill sets and cultural fit, negotiate terms of employment, schedule interviews with hiring managers, 
                        manage candidate pipelines, follow up with clients about interview progress, assist in pre-boarding activities, and ensure smooth 
                        transitions into new roles.</p>
                    <p>Our consultants work closely with employers to understand specific requirements, timelines, budgets, legal constraints, 
                        and brand image expectations, tailoring recruitment solutions accordingly to match job specifications with suitable candidates 
                        efficiently and effectively. </p>
                    <p>Our ultimate goal is to help organizations fill critical roles promptly and seamlessly without compromising quality, thereby 
                        contributing significantly toward enhancing organizational productivity and competitiveness.</p>

                    <h1 style="color:black; font-size: 20px; text-align: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Leadership Hiring:</h1> 
                    <p>We support organizations recruit effective leaders who possess the skills, experience, and qualities necessary for success 
                        within their organization. We typically work with clients who are looking to fill senior positions such as CEOs, COOs, CFOs, 
                        CBOs, CHROs, and other high-level executive roles. </p>  
                    <p>We employ various strategies and techniques to identify candidates with strong leadership potential, including assessing 
                        leadership styles, evaluating past performance, analysing behavioural characteristics, and reviewing educational background 
                        and professional certifications. </p>
                    <p>Once suitable candidates have been identified, we assist with them with the recruitment process, providing guidance on interview 
                        preparation, salary negotiation, and ensuring that candidates are aligned with the organizational culture and values. </p>
                    <p> We always strive to help organizations achieve their strategic objectives by selecting leaders who demonstrate exceptional 
                        performance and contribute positively towards achieving those goals.
                    </p>
                    <h1 style="color:black; font-size: 20px; text-align: left;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Recruitment of Engineers & Executives: </h1> 
                    <p>We focus on connecting qualified professionals with employers seeking to hire experienced engineers and executives across various 
                        industries. Specifically, we target individuals holding degrees from top universities or colleges in various engineering 
                        disciplines (such as electrical, mechanical, computer science, civil etc.) and experienced executives, who hold advanced 
                        degrees like MBAs, PHDs, or other specialized qualifications relevant to the industry niche.  </p>  
                    <p>We specialize in finding talent at all levels - from entry-level graduates just starting out in their careers, to 
                        mid-career professionals looking to transition into new roles, to seasoned executives searching for opportunities to 
                        leverage their extensive expertise. </p>
                    <p>We have dedicated teams composed of experts knowledgeable about both engineering fields and corporate cultures, allowing 
                        us to match job seekers with companies where their skills and experiences align most closely. Additionally, we often utilize 
                        advanced search technologies along with robust networks built over years to facilitate efficient identification and placement 
                        of suitable candidates.  </p>
                    <p> Our services include sourcing, screening, selection, interview coordination, negotiation assistance, contract drafting, 
                        post-placement reviews among others depending upon client requirements. We aim to reduce hiring timelines while maintaining 
                        quality standards and reducing employer costs associated with recruiting processes thereby delivering value added service to 
                        both parties involved.
                    </p>
                    
                    
                    <!-- <ul>
                        <li>IT Engineers</li>
                        <li>Non-IT Engineers (Civil and Construction Engineers, Oil & Gas Engineers, Mechanical & Maintenance Engineers)</li>
                    </ul>
                    <p>There is a huge requirement of Engineers in the field of Information Technology for development and testing. 
                        Also, BPO's & KPO's are also hiring Engineers in large numbers for their operations. Industrial 
                        operations also need Engineers for services like designing, manufacturing, processing and testing.</p>
                    <p>Engineers are responsible for researching and implementing new technologies. In many cases, 
                        Engineers handle management and sales segments for their companies too.  Therefore, Engineers 
                        are an integral part of the economic development process and their demand is very high.</p>
                    <p>We provide both experienced and fresh engineering graduates for a variety of engineering requirements. 
                        We find the right candidates for our clients within a small timeframe with the right technology 
                        background and experience.</p>
                    <h1 style="color:black; font-size: 30px; text-align: left;">Executives</h1>
                    <p>The Accounting & Finance, Human Resource, Sales & Marketing divisions play very critical role in growth 
                        of any organisation. Therefore, companies want the best talent available in the industry 
                        for these functions.</p>
                    <p><strong>JJR Consultancy</strong> specialises in recruitment and placement of individuals for the following 
                        functions:</p>
                    <ul>
                        <li>Accounting</li>
                        <li>Finance</li>
                        <li>Banking</li>
                        <li>Human Resource</li>
                        <li>Sales and Marketing</li>
                    </ul> -->

                    <p></p>
                        <p></p>
                        <p></p>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Main Banner Woman Area -->
<!-- <div class="main-banner-woman-area">
    <div class="container">
        <ul class="banner-woman-list">
            <li>
                <i class="fa-solid fa-user"></i> Over 10 million customers
            </li>
            <li>
                <i class="fa-solid fa-certificate"></i> FCA regulated
            </li>
        </ul>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-woman-content">
                    <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <ul class="btn-list">
                        <li>
                            <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                        </li>
                        <li>
                            <a routerLink="/contact" class="discover-more-btn">Discover More <i class="fa-solid fa-chevron-right"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="main-banner-woman-image">
                    <img src="assets/img/home-six/banner/banner-woman.png" alt="image">
                    <div class="woman-shape">
                        <img src="assets/img/home-six/banner/woman-shape-1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-woman-shape">
        <img src="assets/img/home-six/banner/woman-shape-2.png" alt="image">
    </div>
</div> -->
<!-- End Main Banner Woman Area -->

<!-- Start Partner Area -->
<!-- <div class="partner-style-area pt-100 pb-75">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Partner Area -->

<!-- Start About Us Area -->
<!-- <div class="about-us-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-us-content">
                    <span>About Us</span>
                    <h3>Committed to helping our customers succeed</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum randomised</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6">
                            <ul class="list">
                                <li>
                                    <i class="flaticon-check-mark"></i> Free plan available
                                </li>

                                <li>
                                    <i class="flaticon-check-mark"></i> 100% transparent costs
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <ul class="list">
                                <li>
                                    <i class="flaticon-check-mark"></i> Full data privacy compliance
                                </li>

                                <li>
                                    <i class="flaticon-check-mark"></i> Commitment-free
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="about-btn">
                        <a routerLink="/sign-up" class="sign-up-btn">signup now <i class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-us-right-content">
                    <p>“Dear Customers, we are thrilled you chose to purchase the Tryo from us. We are working hard to build even higher-quality products for our customers. We would love to hear your thoughts and opinion. Please go Tryo to share with us your feedback. Thank you.”</p>
                    <div class="information d-flex align-items-center">
                        <img src="assets/img/home-six/user.png" class="rounded-circle" alt="image">
                        <div class="title">
                            <h3>Alex Smith</h3>
                            <span>Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="about-us-shape">
        <img src="assets/img/home-six/vector-shape-1.png" alt="image">
    </div>
</div> -->
<!-- End About Us Area -->

<!-- Start Flexibility Area -->
<!-- <div class="flexibility-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="flexibility-image">
                    <img src="assets/img/home-six/flexibility.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="flexibility-content">
                    <span>Flexibility</span>
                    <h3>To give you the power to make things happen</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6">
                            <ul class="list">
                                <li>
                                    <i class="flaticon-check-mark"></i> Easy transfers
                                </li>
                                <li>
                                    <i class="flaticon-check-mark"></i> Business without borders
                                </li>
                                <li>
                                    <i class="flaticon-check-mark"></i> International Payments
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <ul class="list">
                                <li>
                                    <i class="flaticon-check-mark"></i>  A powerful open API 
                                </li>
                                <li>
                                    <i class="flaticon-check-mark"></i> Affiliates and partnerships
                                </li>
                                <li>
                                    <i class="flaticon-check-mark"></i> Automated accounting
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum randomised</p>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Flexibility Area -->

<!-- Start Fun Facts Area -->
<!-- <div class="funfacts-style-area">
    <div class="container">
        <div class="funfacts-style-inner-box ptb-100">
            <div class="row">
                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3><span class="odometer" data-count="180">00</span>K</h3>
                        <p>Downloaded</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3><span class="odometer" data-count="20">00</span>K</h3>
                        <p>Feedback</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3><span class="odometer" data-count="500">00</span>+</h3>
                        <p>Workers</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                    <div class="funfact">
                        <h3><span class="odometer" data-count="70">00</span>+</h3>
                        <p>Contrubutors</p>
                    </div>
                </div>
            </div>
            <div class="contact-cta-box">
                <h3>Have any question about us?</h3>
                <p>Don't hesitate to contact us</p>
                <a routerLink="/contact" class="btn btn-primary">Contact Us</a>
            </div>
        </div>
    </div>
</div> -->
<!-- End Fun Facts Area -->

<!-- Start Security Services Area -->
<!-- <div class="security-services-area pt-100 pb-75">
    <div class="container">
        <div class="wrap-section-title">
            <span>Security</span>
            <h2>We show our value by serving faithfully</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="security-services-card">
                    <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>Transparent Pricing</h3>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto</p>
                    <a routerLink="/" class="read-more-btn">Read more <i class="fa-solid fa-chevron-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="security-services-card">
                    <div class="icon">
                        <i class="flaticon-data-encryption"></i>
                    </div>
                    <h3>Fully Encrypted</h3>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto</p>
                    <a routerLink="/" class="read-more-btn">Read more <i class="fa-solid fa-chevron-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="security-services-card">
                    <div class="icon">
                        <i class="flaticon-shield"></i>
                    </div>
                    <h3>Safe and Secure</h3>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto</p>
                    <a routerLink="/" class="read-more-btn">Read more <i class="fa-solid fa-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Security Services Area -->

<!-- Start Awesome Features Area -->
<!-- <div class="awesome-features-area pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="awesome-features-content">
                    <span>Our Awesome Features</span>
                    <h3>Stay ahead of the curve, ready for everything</h3>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-6">
                            <ul class="list">
                                <li>
                                    <i class="flaticon-check-mark"></i> Deadline reminders
                                </li>

                                <li>
                                    <i class="flaticon-check-mark"></i> Information retrieval
                                </li>

                                <li>
                                    <i class="flaticon-check-mark"></i> Email notifications
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <ul class="list">
                                <li>
                                    <i class="flaticon-check-mark"></i>  Drag and drop functionality 
                                </li>

                                <li>
                                    <i class="flaticon-check-mark"></i> Simple dashboard
                                </li>

                                <li>
                                    <i class="flaticon-check-mark"></i> Incredible infrastructure
                                </li>
                            </ul>
                        </div>
                    </div>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum randomised</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="awesome-features-image">
                    <img src="assets/img/home-six/awesome-features.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Awesome Features Area -->

<!-- Start Testimonials Wrap Area -->
<!-- <div class="testimonials-wrap-area ptb-100">
    <div class="container">
        <div class="testimonials-wrap-slides owl-carousel owl-theme">
            <div class="testimonials-card">
                <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself.</p>
                <div class="information d-flex align-items-center justify-content-center">
                    <img src="assets/img/home-six/user.png" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>Alex Smith</h3>
                        <span>Founder & CEO</span>
                    </div>
                </div>
                <div class="vector-icon-image">
                    <img src="assets/img/home-six/vector-icon.png" alt="icon">
                </div>
            </div>
            <div class="testimonials-card">
                <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself.</p>
                <div class="information d-flex align-items-center justify-content-center">
                    <img src="assets/img/home-six/user.png" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>Steven Smith</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
                <div class="vector-icon-image">
                    <img src="assets/img/home-six/vector-icon.png" alt="icon">
                </div>
            </div>
            <div class="testimonials-card">
                <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself.</p>
                <div class="information d-flex align-items-center justify-content-center">
                    <img src="assets/img/home-six/user.png" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>Co-Founder</span>
                    </div>
                </div>
                <div class="vector-icon-image">
                    <img src="assets/img/home-six/vector-icon.png" alt="icon">
                </div>
            </div>
        </div>
    </div>
    <div class="testimonials-wrap-shape">
        <img src="assets/img/home-six/vector-shape-2.png" alt="image">
    </div>
</div> -->
<!-- End Testimonials Wrap Area -->

<!-- Start Blog Wrap Area -->
<!-- <div class="blog-wrap-area pt-100 pb-75">
    <div class="container">
        <div class="wrap-section-title">
            <span>Our Blog</span>
            <h2>We make banking faster & easier, so you can do more</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/blog-details" class="d-block">
                            <img src="assets/img/home-six/blog1.jpg" alt="image">
                        </a>
                        <span class="date">October 30, 2022</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/blog-details">Protect Your Workplace from Cyber Attacks</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read more <i class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/blog-details" class="d-block">
                            <img src="assets/img/home-six/blog2.jpg" alt="image">
                        </a>
                        <span class="date">October 30, 2022</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/blog-details">The security risks of changing package owners</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read more <i class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/blog-details" class="d-block">
                            <img src="assets/img/home-six/blog3.jpg" alt="image">
                        </a>
                        <span class="date">October 30, 2022</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/blog-details">10 Tips To Reduce Your Card Processing Costs</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read more <i class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Blog Wrap Area -->

<!-- Start Subscribe Wrap Area -->
<!-- <div class="subscribe-wrap-area pb-100">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="subscribe-wrap-box-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="subscribe-content">
                            <h3>Subscribe our nesletter</h3>
                            <p>Don’t miss any update on new promotions</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <form class="newsletter-form">
                            <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                            <button type="submit" class="btn btn-primary">Subscribe Now<span></span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Subscribe Wrap Area -->