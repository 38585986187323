<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Labour Contractor</h1>
                    <p><strong>JJR Consultancy </strong>supplies workmen on short / medium / long term basis to cater to the needs of 
                        varied industries. Backed by a huge database of workmen, we can efficiently direct 
                        resources towards the desired jobs. Our services are widely appreciated by our clients in 
                        terms of reliability, cost effectiveness and flexibility.</p>
                    <p>Our workmen have significant experience across a wide range of civil construction, general construction, 
                        engineering and maintenance projects. Our thorough site safety induction programs ensure that they 
                        quickly become productive, when they go to work on project.</p>
                    <p><strong>We supply the following categories of workmen:</strong></p>
                    <ul>
                        <li>Foremen / Supervisors</li>
                        <li>Fitters / Barbenders</li>
                        <li>Mechanics</li>
                        <li>Electricians</li>
                        <li>Technicians</li>
                        <li>Welders / Gas Cutters</li>
                        <li>Masons</li>
                        <li>Grinders</li>
                        <li>Riggers</li>
                        <li>Helpers</li>
                    </ul>
                    <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>