<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Management Profile</h1>
                    <p>JJR Consultancy is led by a team of young professionals with expertise in providing 
                        all kinds of HR services. We pride on our expertise rather than experience. 
                        We believe that, we can exceed the expectations of our clients & candidates 
                        through innovation, dedication and quality services.</p>

                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Main Banner Area -->
<!-- <div class="main-banner-section jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-content">
                            <h1>Easy, fee-free banking for entrepreneurs</h1>
                            <p>Get the financial tools and insights to start, build, and grow your business.</p>
                            <a routerLink="/" class="btn btn-primary">Learn More</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="money-transfer-form">
                            <form>
                                <div class="form-group mb-3">
                                    <label>You Send</label>
                                    <div class="money-transfer-field">
                                        <input type="text" class="form-control" autocomplete="off" value="1,000">
                                        <div class="amount-currency-select">
                                            <select>
                                                <option>USD</option>
                                                <option>EUR</option>
                                                <option>GBP</option>
                                                <option>BRL</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="currency-info">
                                    <div class="bar"></div>
                                    <span><strong>62.3</strong> Exchange Rate</span>
                                    <span><strong>$4.50</strong> Transition Fees</span>
                                </div>
                                <div class="form-group mb-3">
                                    <label>Recipient gets</label>
                                    <div class="money-transfer-field">
                                        <input type="text" class="form-control" autocomplete="off" value="1,000">
                                        <div class="amount-currency-select">
                                            <select>
                                                <option>USD</option>
                                                <option>EUR</option>
                                                <option>GBP</option>
                                                <option>BRL</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="money-transfer-info">
                                    <span>You could save vs banks <strong>1,010.32 USD</strong></span>
                                </div>
                                <button type="submit" class="btn btn-primary">Get Started</button>
                                <div class="terms-info">
                                    <p>By clicking continue, I am agree with <a routerLink="/">Terms & Policy</a></p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Main Banner Area -->

<!-- Start Featured Boxes Area -->
<!-- <div class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div>
                        <h3>Transparent Pricing</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-data-encryption"></i>
                        </div>
                        <h3>Fully Encrypted</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <i class="flaticon-wallet"></i>
                        </div>
                        <h3>Instant Cashout</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>
                        <h3>Safe and Secure</h3>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Featured Boxes Area -->

<!-- Start How It Works Area -->
<!-- <div class="how-it-works-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>How Klob Works</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/how-it-works1.png" alt="image">
                    <h3>1. Register for free</h3>
                    <p>Ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel adipiscing aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/how-it-works2.png" alt="image">
                    <h3>2. Choose an amount to send</h3>
                    <p>Ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel adipiscing aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/how-it-works3.png" alt="image">
                    <h3>3. Add recipient’s bank details</h3>
                    <p>Ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel adipiscing aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/how-it-works4.png" alt="image">
                    <h3>4. Verify your identity</h3>
                    <p>Ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel adipiscing aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/how-it-works5.png" alt="image">
                    <h3>5. Pay for your transfer</h3>
                    <p>Ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel adipiscing aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <img src="assets/img/how-it-works-image/how-it-works6.png" alt="image">
                    <h3>6. That’s it</h3>
                    <p>Ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel adipiscing aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End How It Works Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70 pt-0">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <span class="sub-title">Fast & Easy Online Banking</span>
                    <h2>Freelancers, entrepreneurs, and sole traders</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Apply Now</a>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/4.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/5.png" alt="image">

                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title">Top Security</span>
                    <h2>Small- to medium-sized businesses</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <span class="sub-title">Price Transparency</span>
                    <h2>Large or enterprise level businesses</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Create Account</a>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/6.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/7.jpg" alt="image">
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <span class="sub-title">Automated Accounting</span>
                    <h2>Save 24 hours per week on accounting</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                    <a routerLink="/" class="btn btn-primary">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Comparisons Area -->
<!-- <div class="comparisons-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Compare us with others</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="comparisons-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Packages</th>
                        <th scope="col">Freelancer</th>
                        <th scope="col">Householder</th>
                        <th scope="col">Business Man</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Control payout timing</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Transparent payouts</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Automate evidence submission</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Collaboration notes</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Deposit tagging</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Technical support over IRC</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>24×7 support</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->
<!-- End Comparisons Area -->

<!-- Start Features Area -->
<!-- <div class="features-section ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Our Features</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="features-box-list">
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon">
                                    <i class="flaticon-settings"></i>
                                </div>
                                <h3>Incredible infrastructure</h3>
                                <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-f78acb">
                                    <i class="flaticon-envelope-of-white-paper"></i>
                                </div>
                                <h3>Email notifications</h3>
                                <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-cdf1d8">
                                    <i class="flaticon-menu"></i>
                                </div>
                                <h3>Simple dashboard</h3>
                                <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="features-box">
                                <div class="icon bg-c679e3">
                                    <i class="flaticon-info"></i>
                                </div>
                                <h3>Information retrieval</h3>
                                <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/features-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Features Area -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- Start Fun Facts Area -->
<!-- <app-funfact></app-funfact> -->
<!-- End Fun Facts Area -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-talk-button></app-talk-button> -->

<!-- <app-partner></app-partner> -->

<!-- <app-download></app-download> -->

<!-- Start Blog Area -->
<!-- <div class="blog-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>The News from Our Blog</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog1.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2023
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">The security risks of changing package owners</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog2.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2023
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">Tips to Protecting Business and Family</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog-image/blog3.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 19, 2023
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/blog-details">Protect Your Workplace from Cyber Attacks</a></h3>
                        <span>by <a routerLink="/">admin</a></span>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Blog Area -->

<!-- Start Account Create Area -->
<!-- <app-account-button></app-account-button> -->
<!-- End Account Create Area -->