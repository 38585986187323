<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <!-- <h1 style="color:green; font-size: 30px; text-align: center;">Client Contact Details</h1> -->
                    <br>
                    <!-- <p>Write to us: <strong><a href="mailto:client@jjrconsultancy.com">client@jjrconsultancy.com</a></strong></p> -->
                   
                    <p>Thank you for expressing interest in our services! </p>
                    <div style="margin-left: 40px; font-size: 18px; font-weight: bold;">
                        <ul >
                            <li>Human Capital Advisory </li>
                            <li>Recruitment Services </li>
                            <li>Staffing Solutions & Payroll Management </li>
                            <li>Employee Background Screening  </li>
                            <li>Security Services </li>
                        </ul>
                    </div>
                    <p>Please feel free to send us an email at <strong><a href="mailto:client@jjrconsultancy.com">client@jjrconsultancy.com</a></strong> if you 
                        would like additional details or have specific queries regarding the above services. Our 
                        dedicated team will ensure prompt responses and tailored solutions according to your 
                        unique business needs. </p>

                    <p><strong>Corporate Office:</strong><br>
                        HIG - 89, Ground Floor, Kanan Vihar, <br> 
                        Phase - 1, Patia Square, Bhubaneswar, <br>
                         Odisha, India, 751031 <br>
                        <strong>Tel.</strong> +91 674 3581487 <br>
                        <strong>Mob.</strong> +91 9090919990<br>
                        <strong>Email:</strong><a href="client@jjrconsultancy.com">client@jjrconsultancy.com</a>
                    </p>
                    <!-- <p><strong>Branch Offices:</strong></p>
                    <p>Rourkela, Odisha <br>
                        <strong>Mob.</strong> +91-9937067797, +91-9437255797
                    </p> -->
                    <p></p>
                    <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Page Title Area -->
<!-- <div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>About us</h2>
            <p>The Klob story</p>
        </div>
    </div>
</div> -->
<!-- End Page Title Area -->

<!-- <div class="currency-transfer-provider-with-background-color"> -->
            
    <!-- Start About Area -->
    <!-- <div class="ctp-about-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-image"></div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-about-content">
                        <span>About us</span>
                        <h3>We help transfer money for a better world</h3>
                        <p>Donec sollicitudin molestie malesuada. proin eget tortor risus. nulla quis lorem ut libero malesuada feugiat. donec rutrum congue leo eget malesuada.</p>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, ultrices posuere cubilia curae donec auctor sit amet aliquam vel, ullamcorper sit amet ligula. Lorem ipsum dolor sit amet, consectetur adipiscing elit. pellentesque in ipsum id orci porta dapibus.</p>
                        <h4>Our mission</h4>
                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. lorem ipsum dolor sit amet, ultrices posuere cubilia curae donec consectetur adipiscing elit. pellentesque in ipsum id orci porta dapibus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End About Area -->

    <!-- Start Fun Facts Area -->
    <!-- <div class="ctp-funfacts-area">
        <div class="container">
            <div class="ctp-funfacts-inner-box pt-100 pb-75">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="180">00</span>K+</h3>
                            <p>Happy Customers</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="50">00</span>+</h3>
                            <p>Currencies Offered</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="1">00</span>M+</h3>
                            <p>Customers Globally</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-3 col-md-3 col-6">
                        <div class="ctp-funfact-card">
                            <h3><span class="odometer" data-count="30">00</span>K+</h3>
                            <p>5-Star Reviews</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End Fun Facts Area -->
    
    <!-- Start Choose Area -->
    <!-- <div class="ctp-choose-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-choose-content without-padding">
                        <h3>Why Choose Klob</h3>
                        <p>Nulla quis lorem ut libero malesuada feugiat. curabitur non nulla sit amet nisl tempus convallis quis ac lectus. nulla porttitor accumsan tincidunt. lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="fa-solid fa-check"></i>
                                </div>
                                Bank beating rates
                            </h4>
                            <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. donec rutrum congue leo eget malesuada.</p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="fa-solid fa-check"></i>
                                </div>
                                We're Fast
                            </h4>
                            <p>Nulla porttitor accumsan tincidunt. quisque velit nisi, pretium ut lacinia in, elementum id enim. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi sed porttitor lectus nibh.</p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="fa-solid fa-check"></i>
                                </div>
                                Easy to use
                            </h4>
                            <p>Cras ultricies ligula sed magna dictum porta. donec rutrum congue leo eget malesuada. curabitur aliquet quam id dui posuere blandit. vivamus suscipit tortor eget felis porttitor volutpat. donec sollicitudin molestie malesuada.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-choose-image with-border-radius"></div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End Choose Area -->

    <!-- Start Countries Area -->
    <!-- <div class="ctp-countries-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Popular Countries Our Customers Send Money</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img1.png" alt="image">
                        <span>USA</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img2.png" alt="image">
                        <span>UK</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img3.png" alt="image">
                        <span>New Zealand</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img4.png" alt="image">
                        <span>France</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img5.png" alt="image">
                        <span>Germany</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img6.png" alt="image">
                        <span>Italy</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img7.png" alt="image">
                        <span>Greece</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img8.png" alt="image">
                        <span>China</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img9.png" alt="image">
                        <span>Kuwait</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img10.png" alt="image">
                        <span>Argentina</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img11.png" alt="image">
                        <span>Sweden</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-countries-card">
                        <img src="assets/img/currency-transfer-provider/countries/img12.png" alt="image">
                        <span>Thailand</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End Countries Area -->

    <!-- Start Team Area -->
    <!-- <div class="ctp-team-area pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Executive Team</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="assets/img/currency-transfer-provider/team/team1.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>Michele Allen</h3>
                            <span>Chairman</span>

                            <div class="icon">
                                <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="assets/img/currency-transfer-provider/team/team2.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>James Tucker</h3>
                            <span>Chief Executive Officer</span>
                            <div class="icon">
                                <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="assets/img/currency-transfer-provider/team/team3.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>Doris Dickinson</h3>
                            <span>Director</span>
                            <div class="icon">
                                <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="ctp-team-card">
                        <div class="team-image">
                            <img src="assets/img/currency-transfer-provider/team/team4.jpg" alt="image">
                        </div>
                        <div class="team-content">
                            <h3>William Pipes</h3>
                            <span>Head of Compliance</span>
                            <div class="icon">
                                <a href="#" target="_blank"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End Team Area -->

    <!-- Start App Area -->
    <!-- <div class="ctp-app-area">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-content">
                        <span class="sub">Download App</span>
                        <h3>Let’s get your free copy from apple and play store</h3>
                        <p>Instant free download from store cloud based storage for your data backup just log in with your mail account from play store and using whatever you want for your business purpose.</p>
                        <div class="btn-box">
                            <a href="#" class="app-store-btn" target="_blank">
                                <i class="flaticon-apple"></i>
                                Download on
                                <span>App Store</span>
                            </a>
                            <a href="#" class="play-store-btn" target="_blank">
                                <i class="flaticon-play-store"></i>
                                Download on
                                <span>Google play</span>
                            </a>
                        </div>
                        <div class="info">
                            <span>Over 10 million downloads worldwide</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-app-image">
                        <img src="assets/img/currency-transfer-provider/app/app.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End App Area -->

    <!-- Start Investors Area -->
    <!-- <div class="ctp-investors-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Investors</h2>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <a href="#"><img src="assets/img/currency-transfer-provider/investors/investors1.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <a href="#"><img src="assets/img/currency-transfer-provider/investors/investors2.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <a href="#"><img src="assets/img/currency-transfer-provider/investors/investors3.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <a href="#"><img src="assets/img/currency-transfer-provider/investors/investors4.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <a href="#"><img src="assets/img/currency-transfer-provider/investors/investors5.png" alt="image"></a>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-4">
                    <div class="ctp-investors-card">
                        <a href="#"><img src="assets/img/currency-transfer-provider/investors/investors6.png" alt="image"></a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- End Investors Area -->

<!-- </div> -->