<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<p id="pageheader"></p>
<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Employee Background Screening</h1>
                    <p>JJR Consultancy provides businesses with comprehensive background checks and reports concerning potential hires and current 
                        employees. We collect information through various methods, including public records searches, previous employment verification,
                         education verification, and reference checks. </p>  
                    <p>The purpose of conducting background screenings is to ensure safety, security, compliance, and integrity within organizations by 
                        identifying potential risks before hiring someone. We validate and confirm personal details of applicants, verify previous 
                        employers' references, academic credentials, licenses, certifications etc. This helps protect businesses from potential 
                        liabilities caused due to hiring wrong people which could affect company reputation and productivity negatively.</p>
                    <p>Therefore, investing time and resources in thorough background screening process saves companies from expensive mistakes later on.</p>
                    <!-- <p><strong>Our Features:</strong></p>
                    <ul>
                        <li>Trained Staff</li>
                        <li>Continuous Evaluation</li>
                        <li>Maintain high levels of performance</li>
                    </ul>
                    <p>We provide following <strong>Housekeeping Staffs</strong> to Corporate Houses, Manufacturing & Production Plants, 
                        Construction Sites, Banks, Shopping Malls, Hotels, Multiplexes, Hospitals, Educational Institutes, 
                        Housing Societies and Apartments:Our Features:</p>
                    <ul>
                        <li>Sweepers</li>
                        <li>Maids</li>
                        <li>Cleaning Staffs</li>
                        <li>Gardeners</li>
                        <li>Janitors</li>
                    </ul> -->
                    <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>