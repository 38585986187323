import { Component } from '@angular/core';

@Component({
  selector: 'app-fmcg',
  templateUrl: './fmcg.component.html',
  styleUrls: ['./fmcg.component.scss']
})
export class FmcgComponent {

}
