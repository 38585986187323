<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style=" font-size: 50px; text-align: center;">Retail Sector</h1>
                    <!-- <p><strong>Information Technology</strong> enabled Services is defined as outsourcing of processes that can 
                        be enabled with information technology and covers diverse areas like finance HR, administration, 
                        health care, telecommunication, manufacturing etc. There has been a significant rise in this 
                        field recently. Armed with technology and manpower, these services are provided from e-enabled 
                        locations. This radically reduces costs and improves service standards.</p>
                    <p>In the past few years, many national and international companies have ventured into the retail 
                        sector of India. Various malls, supermarkets and brand retail outlets have been established 
                        all over the country. There has been a rise in the need for efficient manpower in the retail 
                        sector. Our expertise lies in presenting the most suitable manpower for all jobs in this sector.</p>
                    <p>
                        Retail recruitment at <strong>JJR Consultancy</strong> is built on a foundation of real 
                        industry know how. Our consultants are backed by years of industry experience and 
                        therefore truly understand the ever changing environment of the consumer industry and 
                        the diverse needs of our clients.
                    </p>

                    <h1 style="color:green; font-size: 50px; text-align: left;">Hospitality</h1>
                    <p>The Indian hospitality industry has materialized as one of the key drivers of growth among the services
                         sectors in India. It contributes to 6.23 percent to the National GDP and 8.78 percent of the total 
                         employment in the country. It is considered as one of the highly profitable industries contributing 
                         significant amount of foreign exchange to the economy. The Indian hospitality industry has 
                         recorded healthy growth fuelled by robust inflow of foreign tourists as well as increased tourist 
                         movement within the country and it has become one of the leading players in the global industry. 
                         Also, several foreign players have established their strong presence in the country's hospitality spaces.</p>
                    <p>
                        The industry provides employment to skilled, semi-skilled, and unskilled labour directly 
                        and indirectly. Jobs in the hospitality sector require a lot of dedication and patience 
                        along with the necessary professional training. Therefore, we always ensure recruitment of 
                        quality manpower for our clients in this sector.
                    </p> -->
                    <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>