<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="font-size: 50px; text-align: center;">ITES & BPO</h1>
                    <!-- <p><strong>Information Technology</strong> enabled Services is defined as outsourcing of processes that can 
                        be enabled with information technology and covers diverse areas like finance HR, administration, 
                        health care, telecommunication, manufacturing etc. There has been a significant rise in this 
                        field recently. Armed with technology and manpower, these services are provided from e-enabled 
                        locations. This radically reduces costs and improves service standards.</p>
                    <p>Indian Government is making assiduous efforts to promote ITeS. The country is well positioned to 
                        derive benefits from the ITeS market and become a key hub for ITeS services. The country's strengths 
                        in the form of low staff costs, a large pool of skilled, English speaking workforce, conducive policy 
                        environment and Government support have made India a popular choice for customers seeking 
                        outsourced services. Undoubtedly, there are endless opportunities for those, who wish to make 
                        their mark in this field. We offer personalized executive recruitment services to our clients in 
                        ITeS sector in most efficient manner.</p>

                    <h1 style="color:green; font-size: 50px; text-align: left;">BPO</h1>
                    <p>The BPO sector is spread all over India. Now-a-days, not only the International 
                        companies but also the Indian companies are looking for a large number of outsourced workforce. 
                        The trend of BPO has started from foreign countries, but is now growing on a vast scale in India. 
                        We recruit candidates for all job position in call centers, BPO, and operations divisions of 
                        various organisations for pan India locations.</p> -->

                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>