import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

   strUrl:string = "/human-capital-advisory#page-header"
fragment: any;

   getUrl(){
    var localstr = this.strUrl.replace('%23','#');
    return localstr;
   }

  constructor() { }

  ngOnInit() {
  }

}
