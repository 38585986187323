
<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<p id="pageheader"></p>
<div  class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1  style="color:black; font-size: 50px; text-align: center;">Human Capital Advisory</h1>
                    <p>JJR Consultancy specializes in offering businesses with expert advice and guidance related 
                        to managing their workforce more effectively. 
                    </p>
                    <p>We have experienced consultants, who possess extensive knowledge and expertise in areas such as 
                       <strong>organisation structuring and capability building, HR policy formulation & implementation, manpower 
                        planning & budgeting, talent management, performance evaluation, compensation & benefits, managing 
                        attrition & retention strategies, HR analytics and other aspects of HR operations.  </strong>
                    </p>
                    <p>By partnering with us, companies can ensure optimal utilization of their human capital assets, foster 
                        innovation and creativity, enhance employee satisfaction and loyalty, promote corporate values and 
                        culture, achieve regulatory compliance, minimize risks associated with employment practices, 
                        maximize operational efficiency, reduce costs, and ultimately achieve superior business results. 
                    </p>
                    <p>We leverage technology tools to automate certain tasks, streamline procedures, analyse data patterns, 
                        predict future trends, measure HR program impact, fine-tune hiring processes, create customized learning programs, 
                        design incentive schemes, etc.  
                    </p>
                    <p >Overall, we act as trusted partners to organizations striving for excellence and continuous improvement in their HR landscape.
                    </p>

                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Start Main Banner Area -->
<!-- <div class="home-area">
    <div class="container">
        <div class="home-slides owl-carousel owl-theme">
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>Easy, fee-free banking for entrepreneurs</h1>
                            <p>Get the financial tools and insights to start, build, and grow your business.</p>
                            <a routerLink="/" class="btn btn-primary">Get Started</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/banner-image/banner1.jpg" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>Easy, fee-free banking for entrepreneurs</h1>
                            <p>Get the financial tools and insights to start, build, and grow your business.</p>
                            <a routerLink="/" class="btn btn-primary">Get Started</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/banner-image/banner2.jpg" alt="banner-image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Main Banner Area -->

<!-- Start Featured Boxes Area -->
<!-- <div class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div>
                        <h3>Transparent Pricing</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-data-encryption"></i>
                        </div>
                        <h3>Fully Encrypted</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <i class="flaticon-wallet"></i>
                        </div>
                        <h3>Instant Cashout</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>
                        <h3>Safe and Secure</h3>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Featured Boxes Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Freelancers, entrepreneurs, and sole traders</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Small- to medium-sized businesses</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Large or enterprise level businesses</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Comparisons Area -->
<!-- <div class="comparisons-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Compare us with others</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="comparisons-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Packages</th>
                        <th scope="col">Freelancer</th>
                        <th scope="col">Householder</th>
                        <th scope="col">Business Man</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Control payout timing</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Transparent payouts</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Automate evidence submission</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Collaboration notes</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Deposit tagging</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Technical support over IRC</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>24×7 support</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->
<!-- End Comparisons Area -->

<!-- Start Features Area -->
<!-- <div class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Our Features</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-settings"></i>
                    </div>
                    <h3>Incredible infrastructure</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-envelope-of-white-paper"></i>
                    </div>
                    <h3>Email notifications</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-menu"></i>
                    </div>
                    <h3>Simple dashboard</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-info"></i>
                    </div>
                    <h3>Information retrieval</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-cursor"></i>
                    </div>
                    <h3>Drag and drop functionality</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-alarm"></i>
                    </div>
                    <h3>Deadline reminders</h3>
                    <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Features Area -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- Start Fun Facts Area -->
<!-- <app-funfact></app-funfact> -->
<!-- End Fun Facts Area -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-talk-button></app-talk-button> -->

<!-- <app-partner></app-partner> -->

<!-- <app-download></app-download> -->

<!-- Start Account Create Area -->
<!-- <app-account-button></app-account-button> -->
<!-- End Account Create Area -->