import { Component } from '@angular/core';

@Component({
  selector: 'app-power-steel',
  templateUrl: './power-steel.component.html',
  styleUrls: ['./power-steel.component.scss']
})
export class PowerSteelComponent {

}
