import { Component } from '@angular/core';

@Component({
  selector: 'app-pharmaceutical-healthcare',
  templateUrl: './pharmaceutical-healthcare.component.html',
  styleUrls: ['./pharmaceutical-healthcare.component.scss']
})
export class PharmaceuticalHealthcareComponent {

}
