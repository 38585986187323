<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style=" font-size: 50px; text-align: center;">Power</h1>
                    <!-- <p>As the growth in industrial production boosts demand for electricity and power,India has become 
                        the world's fifth largest generator of electricity. JJR Consultancy has vast industry 
                        expertise to ensure suitable candidates for power & transmission sector. Also, 
                        we have come up with the most competent manpower solutions for various companies in this sector.</p>

                        <h1 style="color:green; font-size: 50px; text-align: left;">Steel</h1>
                    <p>India is the fourth largest producer of steel in the world currently and is stated 
                        to become the second largest producer by 2016. Steel production in India has 
                        increased at a CAGR of 6.9 per cent over 2008–12. It is expected that over the years 
                        demand for steel in India is expected to grow magnificently owing to widespread 
                        increase in per capita consumption. We have a flexible approach towards meeting 
                        the diverse job requirements of the steel industry and cater them in the most efficient manner.</p> -->
                        <p></p>
                        <p></p>
                        <p></p>
                </div>

                
            </div>
        </div>
    </div>
</div>