import { Component } from '@angular/core';

@Component({
  selector: 'app-it-bpo',
  templateUrl: './it-bpo.component.html',
  styleUrls: ['./it-bpo.component.scss']
})
export class ItBpoComponent {

}
