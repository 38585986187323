import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-house-keeping-staff',
  templateUrl: './house-keeping-staff.component.html',
  styleUrls: ['./house-keeping-staff.component.scss']
})
export class HouseKeepingStaffComponent {

  constructor(
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {

    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        // Scroll to the element with the fragment's ID
        this.viewportScroller.scrollToAnchor(fragment);
      }
    });
  }

}
