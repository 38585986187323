<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Information Technology</h1>
                    <!-- <p><strong>Information Technology</strong> is one of the most flourishing sectors in India and contributes 
                        the maximum to the economy of the nation. In the past two decades, there have been 
                        significant advancements in IT sector of India. Not only Indian but several multinational 
                        companies have ventured into this Sector. Undoubtedly, there are endless opportunities for those, 
                        who wish to make their mark in the field of IT. Therefore, a career in the Information Technology 
                        sector is one of the most sought after careers in the recent times. The growth in this sector has 
                        led to the increase in number of job opportunities.</p>
                        
                        <p><strong>JJR Consultancy</strong> endeavor to act as a bridge between the deserving 
                            candidates and the hiring IT companies. We are engaged in providing well-qualified 
                            and experienced aspiring candidates. Also, the hiring companies can trust us to provide 
                            them with suitable candidates, who are adept in their field and will contribute in the growth 
                            of the organization.</p> -->
                            <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>