<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Banking & Financial Services</h1>
                    <!-- <p>The Banking, Financial Services and Insurance sector in India has been witnessing a boom with the 
                        entry of a large number of private, govt. and semi govt. banks, financial institutes and insurance 
                        companies. India has one of the fastest growing financial services Industry in the world with an 
                        asset turnover growth of over 30% annually. This industry has been dominated by banking sector, 
                        although its major revenue comes from the insurance sector. Product innovations and increasing 
                        portfolio of services offered to customers are leading to healthy growth in insurance and NBFCs. 
                        We can definitely see it getting a modern outlook with increasing use of technology.</p>
                    
                    <p>Therefore, the banking & financial sector requires a huge number of employees that include 
                        managers, accountants, cashiers, branch heads, financial advisors and many others. In order 
                        to meet the requirements of the sector, <strong>JJR Consultancy</strong> provides experienced & qualified 
                        candidates for various job positions in this sector.</p> -->
                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>

