import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-demo-seven',
  templateUrl: './demo-seven.component.html',
  styleUrls: ['./demo-seven.component.scss']
})
export class DemoSevenComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {

    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        // Scroll to the element with the fragment's ID
        this.viewportScroller.scrollToAnchor(fragment);
      }
    });
  }

}
