import { Component } from '@angular/core';

@Component({
  selector: 'app-labour-employment',
  templateUrl: './labour-employment.component.html',
  styleUrls: ['./labour-employment.component.scss']
})
export class LabourEmploymentComponent {

}
