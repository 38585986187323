import { Component } from '@angular/core';

@Component({
  selector: 'app-labour-contractor',
  templateUrl: './labour-contractor.component.html',
  styleUrls: ['./labour-contractor.component.scss']
})
export class LabourContractorComponent {

}
