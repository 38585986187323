import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DefaultComponent } from './demos/default/default.component';
import { FeedbackComponent } from './common/feedback/feedback.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { FunfactComponent } from './common/funfact/funfact.component';
import { AboutComponent } from './pages/about/about.component';
import { PartnerComponent } from './common/partner/partner.component';
import { FeaturesComponent } from './pages/features/features.component';
import { DownloadComponent } from './common/download/download.component';
import { AccountButtonComponent } from './common/account-button/account-button.component';
import { TalkButtonComponent } from './common/talk-button/talk-button.component';
import { TeamComponent } from './pages/team/team.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { FaqComponent } from './pages/faq/faq.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailsComponent } from './pages/blog-details/blog-details.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DemoFourComponent } from './demos/demo-four/demo-four.component';
import { DemoFiveComponent } from './demos/demo-five/demo-five.component';
import { DemoSixComponent } from './demos/demo-six/demo-six.component';
import { BlogRightSidebarComponent } from './pages/blog-right-sidebar/blog-right-sidebar.component';
import { DemoSevenComponent } from './demos/demo-seven/demo-seven.component';
import { PersonalComponent } from './pages/personal/personal.component';
import { BusinessComponent } from './pages/business/business.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { AboutTwoComponent } from './pages/about-two/about-two.component';
import { ContactTwoComponent } from './pages/contact-two/contact-two.component';
import { CompanyProfileComponent } from './demos/company-profile/company-profile.component';
import { LabourEmploymentComponent } from './demos/labour-employment/labour-employment.component';
import { LabourContractorComponent } from './demos/labour-contractor/labour-contractor.component';
import { OfficeManagementStaffComponent } from './demos/office-management-staff/office-management-staff.component';
import { HouseKeepingStaffComponent } from './demos/house-keeping-staff/house-keeping-staff.component';
import { InfrastructureComponent } from './industries/infrastructure/infrastructure.component';
import { ManufacturingComponent } from './industries/manufacturing/manufacturing.component';
import { PowerSteelComponent } from './industries/power-steel/power-steel.component';
import { FmcgComponent } from './industries/fmcg/fmcg.component';
import { PharmaceuticalHealthcareComponent } from './industries/pharmaceutical-healthcare/pharmaceutical-healthcare.component';
import { InformationTechnologyComponent } from './industries/information-technology/information-technology.component';
import { ItBpoComponent } from './industries/it-bpo/it-bpo.component';
import { TelecomComponent } from './industries/telecom/telecom.component';
import { BankingFinancialServicesComponent } from './industries/banking-financial-services/banking-financial-services.component';
import { EducationComponent } from './industries/education/education.component';
import { RetailHospitalityComponent } from './industries/retail-hospitality/retail-hospitality.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DefaultComponent,
    FeedbackComponent,
    DemoTwoComponent,
    DemoThreeComponent,
    FunfactComponent,
    AboutComponent,
    PartnerComponent,
    FeaturesComponent,
    DownloadComponent,
    AccountButtonComponent,
    TalkButtonComponent,
    TeamComponent,
    PricingComponent,
    NotFoundComponent,
    FaqComponent,
    BlogComponent,
    BlogDetailsComponent,
    SignupComponent,
    SigninComponent,
    ContactComponent,
    DemoFourComponent,
    DemoFiveComponent,
    DemoSixComponent,
    BlogRightSidebarComponent,
    DemoSevenComponent,
    PersonalComponent,
    BusinessComponent,
    HowItWorksComponent,
    AboutTwoComponent,
    ContactTwoComponent,
    CompanyProfileComponent,
    LabourEmploymentComponent,
    LabourContractorComponent,
    OfficeManagementStaffComponent,
    HouseKeepingStaffComponent,
    InfrastructureComponent,
    ManufacturingComponent,
    PowerSteelComponent,
    FmcgComponent,
    PharmaceuticalHealthcareComponent,
    InformationTechnologyComponent,
    ItBpoComponent,
    TelecomComponent,
    BankingFinancialServicesComponent,
    EducationComponent,
    RetailHospitalityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
