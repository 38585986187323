<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">FMCG</h1>
                    <!-- <p>The <strong>FMCG</strong> Sector of India has witnessed significant growth in the past. 
                        This is because of the increased interest of Indian consumers in the fast moving consumer goods. 
                        A number of national and international FMCG Companies have ventured in India to explore the 
                        benefits associated with the rising demands of consumers in India. <strong>FMCG</strong> Industry 
                        is a hot and happening sector in terms of preference of jobseekers.</p>
                    <p>Many proficient individuals are a part of our talent database and we recommend 
                        suitable candidates for all levels and designations in the <strong>FMCG</strong> Sector.</p> -->
                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>