import { Component } from '@angular/core';

@Component({
  selector: 'app-banking-financial-services',
  templateUrl: './banking-financial-services.component.html',
  styleUrls: ['./banking-financial-services.component.scss']
})
export class BankingFinancialServicesComponent {

}
