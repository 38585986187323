<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Company Profile</h1>
                    <p>In the fast-paced dynamic business environment, it is critical to have a recruitment partner, 
                        who truly understands the needs of the clients. With our expertise in global recruitment 
                        and a highly evolved staffing technology, we are best placed to offer top-notch 
                        recruitment solutions. The entire placement process has been designed to shift seamlessly 
                        between corporate focus and personal focus, aligning with the objectives of our clients 
                        and candidates.</p>

                        <p>We provide services to all sectors with excellent quality professionals, workers and other 
                            staffing solutions. We believe that building and maintaining strong relationship is an 
                            integral part of developing a business and ensuring its long term success. 
                            We fulfill the staffing requirements for businesses ranging from multinationals 
                            to small enterprises. As an organization, we work as a Recruitment Consultant with 
                            candidates and as a Recruitment Partner with clients. The needs of our clients are 
                            often diverse and jobs are of wide range. Our aim is to provide value added services to 
                            Clients & Candidates.</p>
                        <p>We build long lasting relationships by partnering with our clients. We invest our time to 
                            understand the business, culture, and current business issues of our clients. This knowledge, 
                            combined with our own professional expertise, allows us to consistently provide top notch 
                            service to our clients.</p>
                        <p>
                            For Candidates, seeking new career opportunities in the market, we provide a confidential 
                            and effective resource to help in managing their careers. Our ongoing relationship with 
                            the top organisations of the region helps us in providing candidates with an efficient 
                            match of their skills and personality with the appropriate client, saving time and effort 
                            in their search.
                        </p>
                        <p></p>
                        <p></p>
                        <p></p>
                </div>
            </div>
        </div>
    </div>
</div>